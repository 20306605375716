import React from "react";
import "./styles.scss";
import { Routes, Route, Navigate } from "react-router-dom";
import Overview from "../overview";
import Header from "../../components/header";
import { Grid } from "@mui/material";
import SideMenu from "../../components/sideMenu";
import { useAuth0 } from "@auth0/auth0-react";

const AppRoutes: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <div className="landing-page">
      <Header />
      {isAuthenticated ? (
        <Grid container className="main" spacing={2}>
          <Grid item xs={2} className="side-menus">
            <SideMenu />
          </Grid>
          <Grid item xs={10} className="content">
            <Routes>
              <Route path="/*" element={<Navigate to={"/overview"} />} />
              <Route path="/overview" element={<Overview />} />
              <Route path="/data-insights" element={<></>} />
              <Route path="/reports" element={<></>} />
            </Routes>
          </Grid>
        </Grid>
      ) : (
        <Grid container className="main" spacing={2}>
          <Grid item xs={12} className="content">
            <Routes>
              <Route path="/*" element={<Navigate to={"/"} />} />
              <Route path="/" element={<Overview />} />
            </Routes>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default AppRoutes;
