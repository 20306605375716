import React, { useEffect } from "react";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./pages/route";
import { useAuth0 } from "@auth0/auth0-react";
import { Loader } from "./components/Loader";
import { UserService } from "./services/user.service";
import { userData } from "./redux/Reducer/userReducer";
import { ErrorLogService } from "./services/errorLog.service";
import { chartData } from "./redux/Reducer/chartDataReducer";
import { LocalDB, initializeDatabase } from "./utils/sql";
import { errorMapper } from "./utils";
import {
  getErrorLogDataFromBackend,
  regSw,
  sendErrorDataToBackend,
  storeDataInLocalSQL,
  subscribe,
} from "./utils/helper";
import { uploadedData } from "./redux/Reducer/uploadedDataReducer";
import { TemplateId } from "./utils/constants";
import { settings } from "./redux/Reducer/settingsReducer";

const App: React.FC = () => {
  const { isLoading, isAuthenticated, user, getIdTokenClaims } = useAuth0();
  const isDarkMode = useSelector((state: any) => state.settings.darkMode);
  const isApiLoading = useSelector((state: any) => state.settings.isLoading);
  const setting = useSelector((state: any) => state?.settings);
  const chartDatas = useSelector((state: any) => state?.chartData);
  const uploadedDatas = useSelector((state: any) => state?.uploadedData);
  const dispatch = useDispatch();

  const UserApi = new UserService();
  const ErrorLogApis = new ErrorLogService();

  const sendUserDataToBackend = async (userDetails: any) => {
    try {
      const resUserData = await UserApi.SaveUser(
        userDetails,
        (await getIdTokenClaims())?.__raw || ""
      );
      dispatch(userData(resUserData.data));
    } catch (error: any) {
      console.log("error", error.message);
    }
  };

  const registerAndSubscribe = async () => {
    try {
      const serviceWorkerReg = await regSw();
      await subscribe(
        serviceWorkerReg,
        (await getIdTokenClaims())?.__raw || ""
      );
    } catch (error) {
      console.log(error);
    }
  };

  const dbOperation = async () => {
    if (uploadedDatas?.uploadedData?.length > 0) {
      LocalDB.exec("delete from error_logs");
      await sendErrorDataToBackend(
        errorMapper(uploadedDatas?.uploadedData, TemplateId.GMATClub),
        getIdTokenClaims,
        ErrorLogApis
      );
      dispatch(
        uploadedData({
          payload: [],
          dbData: [],
        })
      );
      await getErrorLogDataFromBackend(
        getIdTokenClaims,
        dispatch,
        chartData,
        chartDatas,
        isAuthenticated,
        ErrorLogApis
      );
    } else {
      await getErrorLogDataFromBackend(
        getIdTokenClaims,
        dispatch,
        chartData,
        chartDatas,
        isAuthenticated,
        ErrorLogApis
      );
    }
    dispatch(settings({ ...setting, isLoading: false }));
  };

  useEffect(() => {
    initializeDatabase();
    if (isAuthenticated && user) {
      dispatch(settings({ ...setting, isLoading: true }));
      // Send user data to backend
      sendUserDataToBackend(user);
      dbOperation();
      registerAndSubscribe();
    } else {
      setTimeout(() => {
        storeDataInLocalSQL(uploadedDatas?.dbData || []);
      }, 1000);
      dispatch(chartData({ isLoggedOut: true } as any));
      dispatch(settings({ ...setting, isLoading: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user]);

  return (
    <>
      {isLoading || isApiLoading ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            zIndex: 999,
            backgroundColor: isDarkMode ? "#121212" : "#EFEDEC",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader open={isLoading || isApiLoading} />
        </div>
      ) : (
        <Router>
          <Routes>
            <Route path="/*" element={<AppRoutes />} />
          </Routes>
        </Router>
      )}
    </>
  );
};

export default App;
