import * as React from "react";
// import CircularProgress from "@mui/material/CircularProgress";
// import BrandLogo from "../../assets/light/Logo.png";
// import DarkBrandLogo from "../../assets/dark/Logo.png";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import Svg1 from "../../assets/light/logo/logo_t1.svg";
import Svg2 from "../../assets/light/logo/logo_t2.svg";
import Svg3 from "../../assets/light/logo/logo_t3.svg";
import Svg4 from "../../assets/light/logo/logo_t4.svg";

import DarkSvg1 from "../../assets/dark/logo/logo_t1.svg";
import DarkSvg2 from "../../assets/dark/logo/logo_t2.svg";
import DarkSvg3 from "../../assets/dark/logo/logo_t3.svg";
import DarkSvg4 from "../../assets/dark/logo/logo_t4.svg";

type LoaderProps = {
  open: boolean;
};

export const Loader: React.FC<LoaderProps> = ({ open }) => {
  const isDarkMode = useSelector((state: any) => state?.settings?.darkMode);
  const [currentSvgIndex, setCurrentSvgIndex] = useState(0);
  const svgs = [
    { light: Svg1, dark: DarkSvg1, interval: 500 },
    { light: Svg2, dark: DarkSvg2, interval: 750 },
    { light: Svg1, dark: DarkSvg1, interval: 500 },
    { light: Svg3, dark: DarkSvg3, interval: 750 },
    { light: Svg1, dark: DarkSvg1, interval: 500 },
    { light: Svg4, dark: DarkSvg4, interval: 750 },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSvgIndex((prevIndex) => (prevIndex + 1) % svgs.length);
    }, svgs[(currentSvgIndex + 1) % svgs.length].interval);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [svgs.length]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: isDarkMode ? "#FCAC63" : "#74BAD3",
        }}
      >
        <img
          src={svgs[currentSvgIndex][isDarkMode ? "dark" : "light"]}
          alt="loader"
        />
        {/* {svgs[currentSvgIndex]} */}
        {/* <img
          src={isDarkMode ? DarkBrandLogo : BrandLogo}
          alt=""
          className="brand-logo"
        /> */}
        {/* <CircularProgress color="inherit" /> */}
      </div>
    </div>
  );
};
