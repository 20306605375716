import React, { useEffect, useState } from "react";
import BrandLogo from "../assets/light/logo/logo_noshadow.svg";
import DarkBrandLogo from "../assets/dark/logo/logo_noshadow.svg";
import ProfileIcon from "../assets/light/ProfileIcon.png";
import DarkProfileIcon from "../assets/dark/ProfileIcon.png";
import { Grid, Menu, MenuItem } from "@mui/material";
import SearchBar from "./searchBar/searchBar";
import "./styles.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { Icon } from "@mui/material";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import ToggleButton from "./toggleButton";
import { useDispatch, useSelector } from "react-redux";
import { chartData } from "../redux/Reducer/chartDataReducer";
import { settings } from "../redux/Reducer/settingsReducer";
import LoginIcon from "@mui/icons-material/Login";
import ExcelUploadDialog from "./FileDragAndDrop";

const Header: React.FC = () => {
  const { logout, user, isAuthenticated, loginWithRedirect } = useAuth0();
  const [showDragDrop, setShowDragDrop] = useState<boolean>(false);
  const chartDatas = useSelector((state: any) => state?.chartData);
  const subscriptionStatus = useSelector(
    (state: any) => state?.userDetails?.subscriptionStatus
  );

  const [isMoreOptionjsVisible, setIsMoreOptionjsVisible] =
    useState<boolean>(false);
  const isDarkMode = useSelector((state: any) => state?.settings?.darkMode);
  const isVibrate = useSelector(
    (state: any) => state?.isVibrate?.isVibrate?.payload
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    setIsMoreOptionjsVisible(false);
  };

  useEffect(() => {
    const root = document.documentElement;
    if (isDarkMode) {
      root.classList.add("dark-mode");
    } else {
      root.classList.remove("dark-mode");
    }
  }, [isDarkMode]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(chartData({ ...chartDatas, isAuthenticated }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <div className="header">
      <Grid container spacing={2}>
        <Grid item xs={2} className="brand">
          <div
            style={{ display: "flex", height: "54px", alignItems: "center" }}
          >
            <img
              src={isDarkMode ? DarkBrandLogo : BrandLogo}
              alt=""
              className="brand-logo"
            />
            <div
              style={{
                display: "flex",
                fontFamily: "Figtree",
                fontSize: "34px",
                paddingLeft: "8px",
              }}
            >
              <p className="brand-logo-text-1">pocket</p>
              <p className="brand-logo-text-2">bud</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={6} className="search-bar-header">
          {isAuthenticated && <SearchBar />}
        </Grid>
        <Grid
          item
          xs={4}
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <div className="more-options">
            <div className="bulk-upload">
              <label
                className="custom-file-upload"
                onClick={() => {
                  setShowDragDrop(true);
                }}
              >
                <div className="upload-btn">
                  <Icon
                    component={UploadFileOutlinedIcon}
                    className={
                      isDarkMode ? "profile-image-dark" : "profile-image"
                    }
                  />
                </div>
              </label>
            </div>

            <ToggleButton
              setIsToggled={(e: any) => dispatch(settings({ darkMode: e }))}
              isToggled={isDarkMode}
            />
            {isAuthenticated ? (
              <Icon
                component={NotificationsOutlinedIcon}
                className={isDarkMode ? "profile-image-dark" : "profile-image"}
              />
            ) : (
              <Icon
                component={LoginIcon}
                onClick={() => {
                  loginWithRedirect();
                }}
                className={`${
                  isDarkMode ? "profile-image-dark" : "profile-image"
                } ${isVibrate ? "vibrate" : ""}`}
              />
            )}
            <img
              src={
                user?.picture
                  ? user?.picture
                  : isDarkMode
                  ? DarkProfileIcon
                  : ProfileIcon
              }
              alt={"Profile Icon"}
              className={
                user?.picture
                  ? "profile-image"
                  : isDarkMode
                  ? "profile-image-dark"
                  : "profile-image"
              }
              onClick={() => setIsMoreOptionjsVisible(true)}
            />
            <div className="menu-item-wrapper">
              <Menu
                anchorEl={null}
                className="login-menu"
                open={isMoreOptionjsVisible}
                onClose={handleClose}
              >
                {isAuthenticated ? (
                  <>
                    {subscriptionStatus?.flag ? (
                      <MenuItem
                        onClick={() => {
                          setIsMoreOptionjsVisible(false);
                        }}
                      >
                        Referral Link
                      </MenuItem>
                    ) : (
                      <MenuItem
                        onClick={() => {
                          setIsMoreOptionjsVisible(false);
                        }}
                      >
                        Subscription
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => {
                        setIsMoreOptionjsVisible(false);
                        dispatch(chartData({ isLoggedOut: true } as any));
                        logout();
                      }}
                    >
                      Logout
                    </MenuItem>
                  </>
                ) : (
                  <MenuItem
                    onClick={() => {
                      setIsMoreOptionjsVisible(false);
                      loginWithRedirect();
                    }}
                  >
                    Login
                  </MenuItem>
                )}
              </Menu>
            </div>
          </div>
        </Grid>
      </Grid>
      <ExcelUploadDialog
        open={showDragDrop}
        onClose={() => setShowDragDrop(false)}
      />
    </div>
  );
};

export default Header;
