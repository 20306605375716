import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  YAxis,
  LineChart,
  Line,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  Sector,
} from "recharts";
import "./styles.scss";
import { Grid, Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { isVibrate } from "../../redux/Reducer/vibrateLoginReducer";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Dropdown from "../../components/Dropdown";
import { ColorPalette, Modes, Months } from "../../utils/constants";
import { PieSectorDataItem } from "recharts/types/polar/Pie";
import {
  DataModelOfChartAccuracyType,
  DataModelOfChartType,
  ReduxType,
} from "../../utils/types";
import { useAuth0 } from "@auth0/auth0-react";
import QuantIcon from "../../assets/light/Quant.png";
import DataInsightsIcon from "../../assets/light/Data_Insights.png";
import VerbalIcon from "../../assets/light/Verbal.png";

const Overview: React.FC = () => {
  const [mode, setMode] = useState(Modes.Light);
  const [visible, setVisible] = useState([false, false, false]);
  const [activeAccuracyIndex, setActiveAccuracyIndex] = useState(-1);
  const [activeQAIndex, setActiveQAIndex] = useState(-1);
  const [category, setCategory] = useState<string>("");
  const [weeklyTicks, setWeeklyTicks] = useState([0]);
  const [qaClickedPoint, setQAClickedPoint] = useState<{
    cx: number;
    cy: number;
    midAngle: number;
    outerRadius: number;
    innerRadius: number;
  }>({
    cx: 0,
    cy: 0,
    midAngle: 0,
    outerRadius: 0,
    innerRadius: 0,
  });

  const overall = useSelector((state: ReduxType) => state?.chartData?.overall);
  const quant = useSelector((state: ReduxType) => state?.chartData?.quant);
  const di = useSelector((state: ReduxType) => state?.chartData?.di);
  const verbal = useSelector((state: ReduxType) => state?.chartData?.verbal);
  const weekly = useSelector((state: ReduxType) => state?.chartData?.weekly);
  const monthAccuracy = useSelector(
    (state: ReduxType) => state?.chartData?.monthAccuracy
  );
  const accuracy = useSelector(
    (state: ReduxType) => state?.chartData?.accuracy
  );
  const categoryAccuracy = useSelector(
    (state: ReduxType) => state?.chartData?.categoryAccuracy
  );
  const subCategoryAccuracy = useSelector(
    (state: ReduxType) => state?.chartData?.subCategoryAccuracy
  );
  const lastThreeErrorLog = useSelector(
    (state: ReduxType) => state?.chartData?.lastThreeErrorLog
  );
  const monthSelected = useSelector(
    (state: ReduxType) => state?.chartData?.monthSelected
  );

  const isDarkMode = useSelector((state: any) => state?.settings?.darkMode);

  const icons = [
    {
      name: "Quant",
      path: QuantIcon,
      topics: ["Problem Solving (PS)"],
    },
    {
      name: "Data Insights",
      path: DataInsightsIcon,
      topics: [
        "Multi-Source Reasoning (MSR)",
        "Data Sufficiency (DS)",
        "Two-part Analysis (TPA)",
      ],
    },
    {
      name: "Verbal",
      path: VerbalIcon,
      topics: [
        "Critical Reasoning (CR)",
        "Reading Comprehension (RC)",
        "Sentence Correction (EA only)",
      ],
    },
  ];

  const dispatch = useDispatch();

  const { isAuthenticated } = useAuth0();

  const COLORS = ColorPalette?.[mode].pieColorPalette;

  const handleClick = () => {
    dispatch(isVibrate(true));

    setTimeout(() => {
      dispatch(isVibrate(false));
    }, 300);
  };

  const onAccuracyPieClick = (_: any, index: number) => {
    setActiveAccuracyIndex(index === activeAccuracyIndex ? -1 : index);
  };

  const renderActiveShape = (props: PieSectorDataItem) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
      props;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={(outerRadius || 0) + 2}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={(outerRadius || 0) + 1}
          outerRadius={(outerRadius || 0) + 2}
          fill={fill}
        />
      </g>
    );
  };

  const onQAPieClick = (e: any, index: number) => {
    setCategory(e?.payload?.name);
    setActiveQAIndex(index === activeQAIndex ? -1 : index);
    setQAClickedPoint({
      cx: e.cx,
      cy: e.cy,
      midAngle: (e.startAngle + e.endAngle) / 2,
      outerRadius: 70,
      innerRadius: 60,
    });
  };

  const renderConnectingLine = () => {
    if (qaClickedPoint) {
      const { cx, cy, midAngle, outerRadius, innerRadius } = qaClickedPoint;
      const RADIAN = Math.PI / 180;
      const sx = cx + outerRadius * Math.cos(-midAngle * RADIAN);
      const sy = cy + outerRadius * Math.sin(-midAngle * RADIAN);
      const mx = cx + innerRadius * Math.cos(-midAngle * RADIAN);
      const my = cy + innerRadius * Math.sin(-midAngle * RADIAN);

      return (
        <line
          x1={sx}
          y1={sy}
          x2={mx}
          y2={my}
          stroke={ColorPalette?.[mode].ConnectingLine}
          strokeWidth="6"
        />
      );
    }
    return null;
  };

  const getTicks = (maxValue: number, numLabels = 4) => {
    const interval = Math.ceil(maxValue / ((numLabels - 1) * 10)) * 10;
    const ticks = [];
    for (let i = 0; i < numLabels; i++) {
      ticks.push(i * interval);
    }
    setWeeklyTicks(ticks);
  };

  useEffect(() => {
    const maxValue = Math.max(
      ...weekly?.map((d: { correct: number }) => d["correct"])
    );
    getTicks(maxValue);
  }, [weekly]);

  useEffect(() => {
    const delays = [0, 500, 1000];
    if (lastThreeErrorLog?.length > 0) {
      delays.forEach((delay, index) => {
        setTimeout(() => {
          setVisible((prev) => {
            const newVisible = [...prev];
            newVisible[index] = true;
            return newVisible;
          });
        }, delay);
      });
    } else {
      delays.forEach((delay, index) => {
        setTimeout(() => {
          setVisible((prev) => {
            const newVisible = [...prev];
            newVisible[index] = false;
            return newVisible;
          });
        }, delay);
      });
    }
  }, [lastThreeErrorLog]);

  useEffect(() => {
    if (isDarkMode) setMode(Modes.Dark);
    else setMode(Modes.Light);
  }, [isDarkMode]);

  return (
    <div className="overview">
      <Grid container spacing={2}>
        <Grid item xs={12} className="top-section">
          <Grid item xs={12} className="line-chart-row">
            {[
              { name: "Overall", data: overall },
              { name: "Quant", data: quant },
              { name: "DI", data: di },
              { name: "Verbal", data: verbal },
            ].map((x) => {
              return (
                <Grid item xs={2}>
                  <div className="line-chart">
                    <div className="line-chart-name">{x.name}</div>
                    <ResponsiveContainer>
                      <LineChart
                        margin={{ bottom: 18, left: 8, right: 8 }}
                        data={x.data}
                      >
                        <Line
                          type="monotone"
                          dataKey="correct"
                          stroke={ColorPalette?.[mode].topLineCharts}
                          dot={false}
                          strokeWidth={2}
                          onClick={(_e) => {
                            if (!isAuthenticated) handleClick();
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={12} className="main-chart-row">
            <Grid item xs={10}>
              <Grid item xs={12} className="main-chart-row-header">
                <Grid item xs={6}></Grid>
                <Grid item xs={3} style={{ paddingLeft: "58px" }}>
                  <div className="main-chart-row-header-value">{`${monthAccuracy}%`}</div>
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    paddingRight: "68px",
                  }}
                >
                  <Dropdown />
                </Grid>
              </Grid>
              <Grid item xs={12} className="main-chart-row">
                <Grid item xs={6}>
                  <div className="line-chart">
                    <ResponsiveContainer>
                      <LineChart
                        margin={{ bottom: 0, left: 8, right: 8 }}
                        data={weekly.map(
                          (x: DataModelOfChartType, index: number) => {
                            return {
                              ...x,
                              name: `Week ${
                                Number(x.name) - Number(weekly[0].name) + 1
                              }`,
                            };
                          }
                        )}
                      >
                        <CartesianGrid
                          horizontal={true}
                          vertical={false}
                          horizontalValues={weeklyTicks}
                          stroke={ColorPalette?.[mode].CartesianGrid}
                        />
                        <Line
                          type="monotone"
                          dataKey="correct"
                          stroke={ColorPalette?.[mode].weekelyLineCharts}
                          dot={false}
                          strokeWidth={4}
                          onClick={(_e) => {
                            if (!isAuthenticated) handleClick();
                          }}
                        />
                        <YAxis
                          dataKey="correct"
                          axisLine={false}
                          tickLine={false}
                          ticks={weeklyTicks}
                          tickMargin={16}
                        />
                        <XAxis
                          height={50}
                          dataKey="name"
                          axisLine={false}
                          tickLine={false}
                          tickMargin={16}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="pie-chart">
                    <ResponsiveContainer height="80%">
                      <PieChart className="recharts-wrapper">
                        <Pie
                          activeIndex={activeAccuracyIndex}
                          activeShape={renderActiveShape}
                          data={accuracy}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          innerRadius="70%"
                          outerRadius="86%"
                          stroke={ColorPalette?.[mode].pieFill}
                          fill={ColorPalette?.[mode].pieFill}
                          dataKey="value"
                          onClick={(e, i) => {
                            if (!isAuthenticated) handleClick();
                            else onAccuracyPieClick(e, i);
                          }}
                        >
                          {accuracy.map(
                            (
                              _entry: DataModelOfChartAccuracyType,
                              index: number
                            ) => {
                              let color =
                                ColorPalette?.[mode].accuracyPieCorrect;
                              if (_entry.name === "Incorrect")
                                color =
                                  ColorPalette?.[mode].accuracyPieIncorrect;
                              return (
                                <Cell key={`cell-${index}`} fill={color} />
                              );
                            }
                          )}
                        </Pie>
                        {(!!accuracy?.[0]?.value || !!accuracy?.[1]?.value) && (
                          <text
                            x="50%"
                            y="50%"
                            textAnchor="middle"
                            dominantBaseline="middle"
                            fontSize="18"
                            fontWeight="bold"
                            fill={ColorPalette?.[mode].pieLabels}
                            className="pie-chart-legends"
                          >
                            Overall
                          </text>
                        )}
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                    {monthSelected?.month && (
                      <div className="pie-chart-label">
                        {new Date().getMonth() + 1 === monthSelected?.month
                          ? "Accuracy This Month"
                          : `${
                              Months[(monthSelected?.month as number) - 1]
                                ?.value
                            } Month Accuracy`}
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="pie-chart">
                    <ResponsiveContainer height="80%">
                      <PieChart>
                        <Pie
                          data={categoryAccuracy}
                          activeIndex={activeQAIndex}
                          activeShape={renderActiveShape}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          innerRadius="70%"
                          outerRadius="86%"
                          stroke={ColorPalette?.[mode].pieFill}
                          fill={ColorPalette?.[mode].pieFill}
                          dataKey="value"
                          onClick={(e, i) => {
                            if (!isAuthenticated) handleClick();
                            else onQAPieClick(e, i);
                          }}
                        >
                          {categoryAccuracy?.map(
                            (
                              _entry: DataModelOfChartAccuracyType,
                              index: number
                            ) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            )
                          )}
                        </Pie>
                        <Pie
                          data={subCategoryAccuracy[category]}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          innerRadius="46%"
                          outerRadius="60%"
                          stroke={ColorPalette?.[mode].pieFill}
                          dataKey="value"
                          onClick={(_e) => {
                            if (!isAuthenticated) handleClick();
                          }}
                        >
                          {subCategoryAccuracy[category]?.map(
                            (
                              _entry: DataModelOfChartAccuracyType,
                              index: number
                            ) => {
                              let color =
                                ColorPalette?.[mode].accuracyPieCorrect;
                              if (_entry.name === "Incorrect")
                                color =
                                  ColorPalette?.[mode].accuracyPieIncorrect;
                              return (
                                <Cell key={`cell-${index}`} fill={color} />
                              );
                            }
                          )}
                        </Pie>
                        {!!category && (
                          <>
                            <text
                              x="50%"
                              y="45%"
                              textAnchor="middle"
                              dominantBaseline="middle"
                              fontSize="16"
                              fill={ColorPalette?.[mode].pieLabels2}
                            >
                              Total
                            </text>
                            <text
                              x="50%"
                              y="55%"
                              textAnchor="middle"
                              dominantBaseline="middle"
                              fontSize="20"
                              fill={ColorPalette?.[mode].pieLabels3}
                              fontWeight="bold"
                            >
                              {subCategoryAccuracy[category]?.[0]?.value +
                                subCategoryAccuracy[category]?.[1]?.value}
                            </text>
                          </>
                        )}
                        <Tooltip />
                        {renderConnectingLine()}
                      </PieChart>
                    </ResponsiveContainer>
                    <div className="pie-chart-label">Questions Attempted</div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} className="main-chart-legends">
              <Grid item xs={12} className="main-chart-legends-header">
                {Object.keys(subCategoryAccuracy || {})?.length ? (
                  <div className="main-chart-legends-header-label">
                    Categories
                  </div>
                ) : (
                  ""
                )}
              </Grid>
              {Object.keys(subCategoryAccuracy || {}).map(
                (categoryName: string, index: number) => {
                  return (
                    <Grid item xs={12} className="main-chart-legends-data">
                      <Grid
                        item
                        xs={2}
                        className="main-chart-legends-data-color-pallet"
                        style={{
                          backgroundColor: COLORS[index % COLORS.length],
                        }}
                      ></Grid>
                      <Grid
                        item
                        xs={10}
                        className="main-chart-legends-data-value"
                      >
                        {categoryName}
                      </Grid>
                    </Grid>
                  );
                }
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="bottom-section">
          <Grid item xs={6} className="bottom-section-left">
            <Grid item xs={12} className="bottom-section-left-header">
              <Grid item xs={10} className="bottom-section-left-header-label">
                Upcoming Study Recommendations
              </Grid>
              <Grid
                item
                xs={2}
                className={
                  visible[0]
                    ? `bottom-section-left-header-seeall visible ${
                        !isAuthenticated && "locked-content"
                      }`
                    : `bottom-section-left-header-seeall not-visible${
                        !isAuthenticated && "locked-content"
                      }`
                }
              >
                See all
              </Grid>
            </Grid>
            <Grid item xs={12} className="bottom-section-left-values">
              {[
                {
                  name: "Quant",
                  description: "Number Properties",
                  noOfQuestions: "20 Questions",
                  noOfDays: 2,
                },
                {
                  name: "Verbal",
                  description: "(RC) Humanities",
                  noOfQuestions: "5 Passages",
                  noOfDays: 1,
                },
                {
                  name: "Data Insights",
                  description: "Multi Source Reasoning",
                  noOfQuestions: "10 Questions",
                  noOfDays: 4,
                },
              ].map((x) => {
                const icon = icons?.find((y) => y.name === x.name)?.path;
                return (
                  <Grid
                    item
                    xs={4}
                    className={
                      visible[0]
                        ? `bottom-section-left-value visible ${
                            !isAuthenticated && "locked-container"
                          }`
                        : `bottom-section-left-value not-visible ${
                            !isAuthenticated && "locked-container"
                          }`
                    }
                  >
                    {!isAuthenticated && (
                      <Icon
                        component={LockOutlinedIcon}
                        className={
                          isDarkMode
                            ? "profile-image-dark lock-icon"
                            : "profile-image lock-icon"
                        }
                      />
                    )}
                    <Grid
                      item
                      xs={12}
                      className={`bottom-section-left-value-top ${
                        !isAuthenticated && "locked-content"
                      }`}
                    >
                      <Grid
                        item
                        xs={5}
                        className="bottom-section-left-value-top-left"
                      >
                        <img
                          src={icon}
                          style={{
                            filter: isDarkMode ? "invert(0)" : "invert(1)",
                            width: "48px",
                          }}
                          alt="loader"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        className="bottom-section-left-value-top-right"
                      >
                        {x.noOfDays} days
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={`bottom-section-left-value-mid-1 ${
                        !isAuthenticated && "locked-content"
                      }`}
                    >
                      {x.name}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={`bottom-section-left-value-mid-2 ${
                        !isAuthenticated && "locked-content"
                      }`}
                    >
                      {x.description}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={`bottom-section-left-value-bottom ${
                        !isAuthenticated && "locked-content"
                      }`}
                    >
                      {x.noOfQuestions}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <div className="vertical-line"></div>
          <Grid
            item
            xs={6}
            className={`bottom-section-right ${
              !isAuthenticated && "locked-container"
            }`}
          >
            <Grid item xs={12} className="bottom-section-right-header">
              <Grid item xs={10} className="bottom-section-right-header-label">
                Recent Errors
              </Grid>
              <Grid
                item
                xs={2}
                className={
                  visible[0]
                    ? `bottom-section-right-header-seeall visible ${
                        !isAuthenticated && "locked-content"
                      }`
                    : `bottom-section-right-header-seeall not-visible ${
                        !isAuthenticated && "locked-content"
                      }`
                }
              >
                See all
              </Grid>
            </Grid>
            {!isAuthenticated && (
              <Icon
                component={LockOutlinedIcon}
                className={
                  visible[0]
                    ? isDarkMode
                      ? "profile-image-dark lock-icon visible"
                      : "profile-image lock-icon visible"
                    : "not-visible"
                }
              />
            )}
            {(lastThreeErrorLog || []).map((x: any, indux: number) => {
              const icon = icons?.find((y) =>
                y.topics.includes(x.category)
              )?.path;
              return (
                <Grid
                  item
                  xs={12}
                  className={
                    visible[indux]
                      ? `bottom-section-right-value visible ${
                          !isAuthenticated && "locked-content"
                        }`
                      : `bottom-section-right-value not-visible ${
                          !isAuthenticated && "locked-content"
                        }`
                  }
                >
                  <Grid item xs={5} className="bottom-section-right-value-left">
                    <img
                      src={icon}
                      style={{
                        filter: isDarkMode ? "invert(0)" : "invert(1)",
                        width: "40px",
                      }}
                      alt="loader"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    className="bottom-section-right-value-right"
                  >
                    <Grid
                      item
                      xs={12}
                      className="bottom-section-right-value-right-top"
                    >
                      <Grid
                        item
                        xs={11}
                        className="bottom-section-right-value-right-top-left"
                      >
                        {x?.category} - {x?.topic_names}
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        className="bottom-section-right-value-right-top-right"
                      >
                        {x?.time_taken}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      className="bottom-section-right-value-right-bottom"
                    >
                      missed a modifier in the argument which was...
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Overview;
