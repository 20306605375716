import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import { Loader } from "../Loader";
import "./styles.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { convertDbResultToJson, errorMapper } from "../../utils";
import { ErrorLogService } from "../../services/errorLog.service";
import { useDispatch, useSelector } from "react-redux";
import { chartData } from "../../redux/Reducer/chartDataReducer";
import { uploadedData } from "../../redux/Reducer/uploadedDataReducer";
import { LocalDB } from "../../utils/sql";
import {
  getErrorLogDataFromBackend,
  sendErrorDataToBackend,
  storeDataInLocalSQL,
} from "../../utils/helper";
import {
  DiTopics,
  QuantTopics,
  TemplateId,
  VerbalTopics,
} from "../../utils/constants";

const ExcelUploadDialog = ({ open, onClose }: any) => {
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const [excelData, setExcelData] = useState<any>([]);
  const [wholeUploadedData, setWholeUploadedData] = useState<any>([]);
  const [restrictedUploadedData, setRestrictedUploadedData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const chartDatas = useSelector((state: any) => state?.chartData);
  const uploadedDatas = useSelector((state: any) => state?.uploadedData);
  const isDarkMode = useSelector((state: any) => state?.settings?.darkMode);
  const dispatch = useDispatch();
  const ErrorLogApis = new ErrorLogService();

  const onDrop = async (acceptedFiles: any) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    setLoading(true);
    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData: any = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
      const json: any = XLSX.utils.sheet_to_json(firstSheet);
      setWholeUploadedData(json);
      if (!isAuthenticated) setRestrictedUploadedData(json);
      setExcelData(jsonData);
      setLoading(false);
    };
    reader.readAsArrayBuffer(file);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOnSave = async () => {
    if (isAuthenticated) {
      setLoading(true);
      await sendErrorDataToBackend(
        errorMapper(wholeUploadedData, TemplateId.GMATClub),
        getIdTokenClaims,
        ErrorLogApis
      );
      setExcelData([]);
      setWholeUploadedData({});
      setLoading(false);
      onClose();
      setPage(0);
      await getErrorLogDataFromBackend(
        getIdTokenClaims,
        dispatch,
        chartData,
        chartDatas,
        isAuthenticated,
        ErrorLogApis
      );
    } else {
      setExcelData([]);
      setWholeUploadedData({});
      setLoading(false);
      onClose();
      setPage(0);
      const tempRestrictedUploadedData: any = {
        quantTopics: [],
        diTopics: [],
        verbalTopics: [],
      };
      const tempRestrictedUploadedDataArray: any = [];
      [...restrictedUploadedData]
        ?.sort(
          (a: any, b: any) =>
            new Date(b["Date"]).getTime() - new Date(a["Date"]).getTime()
        )
        .some((element: any) => {
          if (
            tempRestrictedUploadedData.quantTopics.length === 20 &&
            tempRestrictedUploadedData.diTopics.length === 20 &&
            tempRestrictedUploadedData.verbalTopics.length === 20
          )
            return true;
          else {
            if (
              tempRestrictedUploadedData.quantTopics.length !== 20 &&
              QuantTopics.includes(element["Forum name"])
            ) {
              tempRestrictedUploadedData.quantTopics.push(element);
              tempRestrictedUploadedDataArray.push(element);
            } else if (
              tempRestrictedUploadedData.diTopics.length !== 20 &&
              DiTopics.includes(element["Forum name"])
            ) {
              tempRestrictedUploadedData.diTopics.push(element);
              tempRestrictedUploadedDataArray.push(element);
            } else if (
              tempRestrictedUploadedData.verbalTopics.length !== 20 &&
              VerbalTopics.includes(element["Forum name"])
            ) {
              tempRestrictedUploadedData.verbalTopics.push(element);
              tempRestrictedUploadedDataArray.push(element);
            } else return false;
            return false;
          }
        });
      LocalDB.exec("delete from error_logs");
      storeDataInLocalSQL(
        errorMapper([...tempRestrictedUploadedDataArray], TemplateId.GMATClub)
      );
      dispatch(
        uploadedData({
          ...uploadedDatas,
          payload: wholeUploadedData,
          dbData: convertDbResultToJson(
            LocalDB.exec("select * from error_logs"),
            "allData"
          ),
        })
      );
      dispatch(chartData({ ...chartDatas, isAuthenticated }));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      PaperProps={{
        style: {
          height: "80vh",
        },
      }}
      fullWidth
      id="dialog-container"
    >
      <div className="dialog-container">
        <div style={{ height: "100%" }}>
          <DialogTitle className="header">Upload Excel File</DialogTitle>
          <DialogContent className="message-container">
            {!loading && excelData.length === 0 && (
              <div
                {...getRootProps()}
                style={{
                  border: "2px dashed #cccccc",
                  padding: "20px",
                  textAlign: "center",
                  cursor: "pointer",
                  height: "90%",
                }}
              >
                <input {...getInputProps()} />
                <p className="message">
                  Drag & drop an Excel file here, or click to select a file
                </p>
              </div>
            )}
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Loader open={loading} />
              </div>
            )}
            {!loading && excelData.length > 0 && (
              <>
                <Table>
                  <TableHead>
                    <TableRow sx={{ color: isDarkMode ? "white" : "black" }}>
                      {excelData[0].map((col: any, index: any) => (
                        <TableCell
                          key={index}
                          sx={{ color: isDarkMode ? "white" : "black" }}
                        >
                          {col}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {excelData
                      .slice(
                        page * rowsPerPage + 1,
                        page * rowsPerPage + rowsPerPage + 1
                      )
                      .map((row: any, rowIndex: any) => (
                        <TableRow
                          key={rowIndex}
                          sx={{ color: isDarkMode ? "white" : "black" }}
                        >
                          {row.map((cell: any, cellIndex: any) => (
                            <TableCell
                              key={cellIndex}
                              sx={{ color: isDarkMode ? "white" : "black" }}
                            >
                              {cell}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[20, 40, 60]}
                  component="div"
                  count={excelData.length - 1}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </DialogContent>
          {!loading && (
            <DialogActions className="action-container">
              <Button
                onClick={() => {
                  setExcelData([]);
                  setWholeUploadedData({});
                  setLoading(false);
                  onClose();
                  setPage(0);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleOnSave();
                }}
              >
                Save
              </Button>
            </DialogActions>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ExcelUploadDialog;
