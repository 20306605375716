import { BaseService } from "./base.service";


export class ErrorLogService extends BaseService {
    public async SaveErrorLog(errorData: any, token: string) {
        try {
            const { data } = await this.httpClient.post("error-log/save", errorData, {
                headers:
                    { Authorization: `Bearer ${token}` }
            });

            if (data.status === "success") {
                return data;
            }
        }
        catch (error) {
        }
    }

    public async StartSummary(jobId: string, token: string) {
        try {
            const { data } = await this.httpClient.post("error-log/job", { jobId }, {
                headers:
                    { Authorization: `Bearer ${token}` }
            });

            if (data.status === "success") {
                return data;
            }
        }
        catch (error) {
        }
    }

    public async GetErrorLog(token: string) {
        try {
            const { data } = await this.httpClient.get("error-log", {
                headers:
                    { Authorization: `Bearer ${token}` }
            });

            if (data.status === "success") {
                return data;
            }
        }
        catch (error) {
        }
    }
}