import { createSlice } from '@reduxjs/toolkit';
import { SubscriptionStatus } from '../../utils/constants';

const initialState = {
    userDetails: {}
};

const UserReducer = createSlice({
    name: 'userReducer',
    initialState,
    reducers: {
        userData(state: any, payload) {
            state.userDetails = payload.payload
            if (payload?.payload?.sub_status === SubscriptionStatus.Active) {
                state.subscriptionStatus = {
                    state: SubscriptionStatus.Active,
                    flag: true
                }
            } else if (payload?.payload?.sub_status === SubscriptionStatus.Expired) {
                state.subscriptionStatus = {
                    state: SubscriptionStatus.Expired,
                    flag: false
                }
            } else {
                state.subscriptionStatus = {
                    state: SubscriptionStatus.Inactive,
                    flag: false
                }
            }
        }
    }
});

export const { userData } = UserReducer.actions;
export default UserReducer.reducer;
