import React, { useState } from "react";
import "./styles.scss"; // Import the SCSS file for styling
import CaretDownIcon from "../../assets/light/CaretDown.png";
import DarkCaretDownIcon from "../../assets/dark/CaretDown.png";
import { chartData } from "../../redux/Reducer/chartDataReducer";
import { useDispatch, useSelector } from "react-redux";
import { Months } from "../../utils/constants";
import { ReduxType } from "../../utils/types";

const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isDarkMode = useSelector((state: any) => state?.settings?.darkMode);
  const chartDatas = useSelector((state: any) => state.chartData);
  const selectedMonth = useSelector(
    (state: any) => state.chartData.monthSelected
  );
  const allMonths = useSelector(
    (state: ReduxType) => state?.chartData?.allMonths
  );
  const dispatch = useDispatch();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (month: { year: number; month: number }) => {
    dispatch(chartData({ ...chartDatas, monthSelected: month }));
    setIsOpen(false);
  };

  return (
    <div className="dropdown">
      <button className="dropdown-button" onClick={toggleDropdown}>
        {
          Months?.find(
            (x) => selectedMonth?.month && x.id === selectedMonth?.month
          )?.value
        }
        -{selectedMonth?.year?.toString()?.slice(-2)}
        {isOpen ? (
          <img
            src={isDarkMode ? DarkCaretDownIcon : CaretDownIcon}
            alt=""
            className="arrow-inverse"
          />
        ) : (
          <img
            src={isDarkMode ? DarkCaretDownIcon : CaretDownIcon}
            alt=""
            className="arrow"
          />
        )}
      </button>
      {isOpen && (
        <ul className="dropdown-menu">
          {allMonths.map((month: any) => (
            <li
              key={`${month.year}-${month.month}`}
              onClick={() => handleSelect(month)}
            >
              {Months?.find((x) => x.id === month.month)?.value}-
              {month.year.slice(-2)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
