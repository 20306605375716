import "./styles.scss";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import DashboardIcon from "../assets/light/dashboard/Dashboard.png";
import DashboardActiveIcon from "../assets/light/dashboard/DashboardActive.png";
// import ReceiptIcon from "../assets/light/receipt/Receipt.png";
// import ReceiptActiveIcon from "../assets/light/receipt/ReceiptActive.png";
import ReportIcon from "../assets/light/report/Report.png";
import ReportActiveIcon from "../assets/light/report/ReportActive.png";
import ArrowIcon from "../assets/light/ArrowRight.png";
import DarkDashboardIcon from "../assets/dark/dashboard/Dashboard.png";
import DarkDashboardActiveIcon from "../assets/dark/dashboard/DashboardActive.png";
// import DarkReceiptIcon from "../assets/dark/receipt/Receipt.png";
// import DarkReceiptActiveIcon from "../assets/dark/receipt/ReceiptActive.png";
import DarkReportIcon from "../assets/dark/report/Report.png";
import DarkReportActiveIcon from "../assets/dark/report/ReportActive.png";
import DarkArrowIcon from "../assets/dark/ArrowRight.png";
import { useSelector } from "react-redux";
import { Icon } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

type sideMenuItemProps = {
  name: string;
  navigationLink: string;
  icon: any;
  activeIcon: any;
  darkActiveIcon: any;
  darkIcon: any;
  disabled?: boolean;
};

const SideMenuItem: React.FC<sideMenuItemProps> = ({
  name,
  navigationLink,
  icon,
  activeIcon,
  darkActiveIcon,
  darkIcon,
  disabled,
}) => {
  const [activeMenuStatus, setActiveMenuStatus] = useState<boolean>(false);
  const isDarkMode = useSelector((state: any) => state?.settings?.darkMode);

  return (
    <>
      {disabled ? (
        <div className={"navigation-link locked-sidemenu"}>
          <div className="sidemenu-item locked-sidemenu">
            {/* <img
              className="menu-img"
              alt=""
              src={isDarkMode ? darkIcon : icon}
            /> */}
            <Icon
              component={LockOutlinedIcon}
              className={
                !isDarkMode ? "lock-icon-side-menu" : "lock-icon-side-menu-dark"
              }
            />
            <h1>{name}</h1>
          </div>
        </div>
      ) : (
        <NavLink
          to={navigationLink}
          className={({ isActive }) => {
            setActiveMenuStatus(isActive);
            return isActive ? "navigation-link active" : "navigation-link";
          }}
        >
          {activeMenuStatus ? (
            <div className="sidemenu-item-active">
              <div className="sub-menu-left">
                <img
                  className="menu-img"
                  alt=""
                  src={isDarkMode ? darkActiveIcon : activeIcon}
                />
                <h1>{name}</h1>
              </div>
              <img
                className="menu-img"
                alt=""
                src={isDarkMode ? DarkArrowIcon : ArrowIcon}
              />
            </div>
          ) : (
            <div className="sidemenu-item">
              <img
                className="menu-img"
                alt=""
                src={isDarkMode ? darkIcon : icon}
              />
              <h1>{name}</h1>
            </div>
          )}
        </NavLink>
      )}
    </>
  );
};

const SideMenu: React.FC = () => {
  return (
    <div className={"sidemenu"}>
      <div className="side-menu">
        <div className="sidemenu-items">
          <SideMenuItem
            icon={DashboardIcon}
            darkIcon={DarkDashboardIcon}
            darkActiveIcon={DarkDashboardActiveIcon}
            name="Overview"
            navigationLink="/overview"
            activeIcon={DashboardActiveIcon}
          />
          {/* <SideMenuItem
            darkIcon={DarkReceiptIcon}
            darkActiveIcon={DarkReceiptActiveIcon}
            name="Data Insights"
            navigationLink="/data-insights"
            icon={ReceiptIcon}
            activeIcon={ReceiptActiveIcon}
          /> */}
          <SideMenuItem
            darkIcon={DarkReportIcon}
            darkActiveIcon={DarkReportActiveIcon}
            name="Reports"
            navigationLink="/reports"
            icon={ReportIcon}
            activeIcon={ReportActiveIcon}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
