import { createSlice } from '@reduxjs/toolkit';
import { convertDbResultToJson } from '../../utils';
import { LocalDB } from '../../utils/sql';
import { accuracyChartQuery, categoryChartQuery, diQuery, lastThreeErrorLogQuery, monthsQuery, overallQuery, quantQuery, subCategoryChartQuery, verbalQuery, weekyChartQuery } from '../../utils/query';
import { ChartReduxPayloadType, ChartReduxType } from '../../utils/types';

const initialState: ChartReduxType = {
    overall: [{ name: 1, correct: 0, incorrect: 0 }, { name: 2, correct: 0, incorrect: 0 }],
    verbal: [{ name: 1, correct: 0, incorrect: 0 }, { name: 2, correct: 0, incorrect: 0 }],
    di: [{ name: 1, correct: 0, incorrect: 0 }, { name: 2, correct: 0, incorrect: 0 }],
    quant: [{ name: 1, correct: 0, incorrect: 0 }, { name: 2, correct: 0, incorrect: 0 }],
    weekly: [{ name: 1, correct: 0, incorrect: 0 }, { name: 2, correct: 0, incorrect: 0 }],
    accuracy: [{ name: "Correct", value: 0 }, { name: "Incorrect", value: 0 }],
    categoryAccuracy: [{ name: "Correct", value: 0 }, { name: "Incorrect", value: 0 }],
    subCategoryAccuracy: {},
    lastThreeErrorLog: [],
    monthAccuracy: 0,
    monthSelected: {},
    isAuthenticated: false,
    isReveal: false,
    allMonths: []
};

const ChartDataReducer = createSlice({
    name: 'ChartDataReducer',
    initialState,
    reducers: {
        chartData(state: ChartReduxType, payload: ChartReduxPayloadType) {
            const isLoggedOut = payload?.payload?.isLoggedOut
            if (isLoggedOut) {
                state.overall = [{ name: 1, correct: 0, incorrect: 0 }, { name: 2, correct: 0, incorrect: 0 }]
                state.verbal = [{ name: 1, correct: 0, incorrect: 0 }, { name: 2, correct: 0, incorrect: 0 }]
                state.di = [{ name: 1, correct: 0, incorrect: 0 }, { name: 2, correct: 0, incorrect: 0 }]
                state.quant = [{ name: 1, correct: 0, incorrect: 0 }, { name: 2, correct: 0, incorrect: 0 }]
                state.weekly = [{ name: 1, correct: 0, incorrect: 0 }, { name: 2, correct: 0, incorrect: 0 }]
                state.accuracy = [{ name: "Correct", value: 0 }, { name: "Incorrect", value: 0 }]
                state.categoryAccuracy = [{ name: "Correct", value: 0 }, { name: "Incorrect", value: 0 }]
                state.subCategoryAccuracy = {}
                state.lastThreeErrorLog = []
                state.monthAccuracy = 0
                state.monthSelected = {}
                state.isAuthenticated = false
                state.isReveal = false
                state.allMonths = []

            } else {

                state.isAuthenticated = payload?.payload?.isAuthenticated

                // Setting Month - Start
                if (!!payload?.payload?.monthSelected)
                    state.monthSelected = payload?.payload?.monthSelected;
                else
                    state.monthSelected = state?.allMonths?.[0]
                // Setting Month - End

                if (LocalDB) {

                    // All Months - Start
                    const allMonths = LocalDB.exec(monthsQuery());
                    console.log("allMonths", allMonths)
                    if (allMonths.length > 0) state.allMonths = convertDbResultToJson(allMonths, "allMonths")?.map((x: { month: string }) => { return { ...x, month: Number(x.month) } });
                    else state.allMonths = []
                    // All Months - End

                    // Setting Month - Start
                    if (!!payload?.payload?.monthSelected && JSON.stringify(payload?.payload?.monthSelected) !== '{}')
                        state.monthSelected = payload?.payload?.monthSelected;
                    else
                        state.monthSelected = state?.allMonths?.[0]
                    // Setting Month - End

                    // Overall Chart - Start
                    const overallData = LocalDB.exec(overallQuery());
                    if (overallData.length > 0) state.overall = convertDbResultToJson(overallData, "overallData");
                    else state.overall = [{ name: 1, correct: 0, incorrect: 0 }, { name: 2, correct: 0, incorrect: 0 }]
                    // Overall Chart - End

                    // Quant Chart - Start
                    const quantData = LocalDB.exec(quantQuery());
                    if (quantData.length > 0) state.quant = convertDbResultToJson(quantData, "quantData");
                    else state.verbal = [{ name: 1, correct: 0, incorrect: 0 }, { name: 2, correct: 0, incorrect: 0 }]
                    // Quant Chart - End

                    // Di Chart - Start
                    const diData = LocalDB.exec(diQuery());
                    if (diData.length > 0) state.di = convertDbResultToJson(diData, "diData");
                    else state.di = [{ name: 1, correct: 0, incorrect: 0 }, { name: 2, correct: 0, incorrect: 0 }]
                    // Di Chart - End

                    // Verbal Chart - Start
                    const verbalData = LocalDB.exec(verbalQuery());
                    if (verbalData.length > 0) state.verbal = convertDbResultToJson(verbalData, "verbalData");
                    else state.quant = [{ name: 1, correct: 0, incorrect: 0 }, { name: 2, correct: 0, incorrect: 0 }]
                    // Verbal Chart - End

                    // Weekly Chart - Start
                    const weeklyData = LocalDB.exec(weekyChartQuery(state?.monthSelected?.month || 0, state?.monthSelected?.year || ""));
                    if (weeklyData.length > 0) state.weekly = convertDbResultToJson(weeklyData, "weeklyData");
                    else state.weekly = [{ name: 1, correct: 0, incorrect: 0 }, { name: 2, correct: 0, incorrect: 0 }]
                    // Weekly Chart - End

                    // Accuracy - Start
                    const accuracyData = LocalDB.exec(accuracyChartQuery(state?.monthSelected?.month || 0, state?.monthSelected?.year || ""));
                    if (accuracyData.length > 0) state.accuracy = convertDbResultToJson(accuracyData, "accuracyData");
                    else state.accuracy = [{ name: "Correct", value: 0 }, { name: "Incorrect", value: 0 }]
                    // Accuracy - End

                    // Category - Start
                    const categoryData = LocalDB.exec(categoryChartQuery(state?.monthSelected?.month || 0, state?.monthSelected?.year || ""));
                    if (categoryData.length > 0) state.categoryAccuracy = convertDbResultToJson(categoryData, "categoryData");
                    else state.categoryAccuracy = [{ name: "Correct", value: 0 }, { name: "Incorrect", value: 0 }]
                    // Category - End

                    // Subcategory - Start
                    const tempSubCategoryAccuracyChartValues: any = {};
                    if (categoryData.length > 0) {
                        state?.categoryAccuracy?.forEach((cat: any) => {
                            const subCategoryData = LocalDB.exec(subCategoryChartQuery(state?.monthSelected?.month || 0, state?.monthSelected?.year || "", cat.name));
                            tempSubCategoryAccuracyChartValues[cat.name] = convertDbResultToJson(subCategoryData, "subCategoryData");
                        })
                        state.subCategoryAccuracy = tempSubCategoryAccuracyChartValues
                    } else state.subCategoryAccuracy = {}
                    // Subcategory - End

                    // Category - Start
                    const lastThreeErrorLogData = LocalDB.exec(lastThreeErrorLogQuery());
                    if (lastThreeErrorLogData.length > 0) state.lastThreeErrorLog = convertDbResultToJson(lastThreeErrorLogData, "lastThreeErrorLogData");
                    else state.lastThreeErrorLog = []
                    // Category - End

                    // Month Accuracy - Start
                    let totalValuePerMonth = 0
                    let totalCorrectValuePerMonth = 0
                    if (overallData.length > 0) state.accuracy.forEach((element: any) => {
                        if (element.name === "Correct") totalCorrectValuePerMonth = element.value
                        totalValuePerMonth += element.value
                    });
                    if (totalCorrectValuePerMonth && totalValuePerMonth)
                        state.monthAccuracy = Number(((totalCorrectValuePerMonth / totalValuePerMonth) * 100).toFixed(2))
                    else state.monthAccuracy = 0
                    // Month Accuracy - End

                } else {
                    state.overall = []
                    state.verbal = []
                    state.di = []
                    state.quant = []
                    state.weekly = []
                    state.accuracy = []
                    state.categoryAccuracy = []
                    state.subCategoryAccuracy = {}
                    state.lastThreeErrorLog = []
                    state.monthAccuracy = 0
                    state.allMonths = []
                }
            }

        }
    }
});

export const { chartData } = ChartDataReducer.actions;
export default ChartDataReducer.reducer;
