import axios, { AxiosInstance } from "axios";

export class BaseService {
    httpClient: AxiosInstance;

    constructor() {

        this.httpClient = axios.create({
            baseURL: process.env.REACT_APP_DREAMFYER_URL,
        });
    }
}
