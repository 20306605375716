
import "./styles.scss";
import LightMoonIcon from "../../assets/light/LightMoon.png";
import DarkMoonIcon from "../../assets/dark/DarkMoon.png";

const ToggleButton = ({ isToggled, setIsToggled }: any) => {
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <div
      className={`toggle-button ${isToggled ? "toggled" : ""}`}
      onClick={handleToggle}
    >
      <div className={`toggle-thumb ${isToggled ? "toggled" : ""}`}>
        {isToggled ? (
          <img src={DarkMoonIcon} alt="Drak Mode" className={`search-icon`} />
        ) : (
          <img src={LightMoonIcon} alt="Drak Mode" className={`search-icon`} />
        )}
      </div>
    </div>
  );
};

export default ToggleButton;
