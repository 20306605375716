import { BaseService } from "./base.service";


export class SubscribeService extends BaseService {
    public async subscribe(swData: any, token: string) {
        try {
            const { data } = await this.httpClient.post("subscribe", swData, {
                headers:
                    { Authorization: `Bearer ${token}` }
            });

            if (data.status === "success") {
                return data;
            }
        }
        catch (error) {
        }
    }
}