import "./styles.scss";
import SearchIcon from "../../assets/light/MagnifyingGlass.png";
import DarkSearchIcon from "../../assets/dark/MagnifyingGlass.png";
import { useState } from "react";
import { useSelector } from "react-redux";

const SearchBar = () => {
  const [isIconVisible, setIsIconVisible] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const isDarkMode = useSelector((state: any) => state?.settings?.darkMode);

  const handleClick = () => {
    setIsIconVisible(false);
  };

  const handleBlur = () => {
    // Check if search field is empty on blur
    if (searchValue === "") {
      setIsIconVisible(true);
    }
  };

  const handleChange = (e: any) => {
    setSearchValue(e.target.value);
    // Always hide the icon when there is text in the input
    if (e.target.value !== "") {
      setIsIconVisible(false);
    }
  };
  return (
    <div className="search-bar">
      <img
        src={isDarkMode ? DarkSearchIcon : SearchIcon}
        alt="Search Icon"
        className={`search-icon ${isIconVisible ? "visible" : "hidden"}`}
      />
      <input
        type="text"
        placeholder="Search"
        onFocus={handleClick}
        onBlur={handleBlur}
        onChange={handleChange}
        value={searchValue}
      />
    </div>
  );
};

export default SearchBar;
